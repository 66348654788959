<template>
  <div style="background: #f5f5f5;color: #000;height: 100%;" class="miao">
    <van-nav-bar title left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <!--<van-nav-bar title="秒杀列表" left-arrow :fixed="true" :z-index="999" @click-left="back" v-else>
      <template #right></template>
    </van-nav-bar>-->
    <div style="padding-top:50px;background: #f5f5f5;">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoads"
        :offset="10"
      >
        <div class="mian" @click="xiangqing(item)" v-for="(item,idx) in list" :key="idx">
          <div class="left mian_a">
            <img class="order_img" v-if="item.type == 0" :src="item.coverImg1" alt />
            <img class="order_img" v-else src="../../assets/img/taocan.png" alt />
          </div>
          <div class="left mian_b">
            <div class="shuo_a">{{item.partName}}</div>
            <div class="shuo_b">{{item.produceExplain}}</div>

            <!-- <div class="shuo_c">
              <span>{{item.title1}}</span>
              <span>{{item.title2}}</span>
            </div>-->
            <div class="jiage">
              <div class="jiage_d">
                <span class="danwei">￥</span>
                <span class="mone">{{item.activityPrice}}</span>
                <del class="qiangou">￥{{item.sellPrice}}</del>
                <div class="shen" v-if="item.type == 0">
                  <span v-if="item.stockCount > 0">剩余:{{item.stockCount}}件</span>
                  <span v-else class="shouqin">已售罄</span>
                </div>
                <div class="shen" v-else>限时抢购</div>
              </div>
              <div class="qianggou">
                <div class="qgg">去抢购</div>
                <div class="jintu">
                  <van-progress
                    class="left"
                    color="#fff"
                    track-color="rgb(255, 255, 255, 0.3)"
                    :percentage="( item.stockCount / item.num)*100"
                    :show-pivot="false"
                  />
                  <span class="left jindu">{{Math.ceil(( item.stockCount / item.num)*100)}}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <van-empty v-if="list.length < 1" description="暂无数据~" />
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      list: [],

      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    xiangqing(item) {
      if (item.type == 0) {
        this.$router.push({
          path: "list_miao_details",
          query: {
            id: item.id,
            Aswitch: 2
          }
        });
      } else {
        this.$router.push({
          path: "member_details"
        });
        item.name = item.partName;
        item.type = 4;
        sessionStorage.setItem("huiyuan", JSON.stringify(item));
      }
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        gid: this.shangjia.id,
        page: this.page,
        size: this.size,
        type:0
      };
      userModel
        .miaoshalist(data)
        .then(e => {
          loading.clear();
          let rows = e.data;
          this.loading = false;
          this.total = e.pageInfo.total;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      this.page++;
      this.onLoad();
    },
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/user";
    //     },
    //     false
    //   );
    // },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });f
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.qiehuan {
  width: 13px;
  height: 13px;
  vertical-align: middle;
  margin-right: 4px;
}
.order_img {
  height: 100px;
  width: 100px;
}
.mian {
  padding: 10px 10px;
  overflow: hidden;
  background: #fff;
  border-radius: 15px;
  margin: 12px 12px;
}
.mian_a {
  width: 100px;
}
.mian_b {
  font-size: 14px;
  margin-left: 10px;
  width: 215px;
}
.ciri {
  display: inline-block;
  background: #deeff4;
  color: #55bae2;
  line-height: 12px;
  padding: 0 1px;
  font-size: 9px;
  border-radius: 3px;
  border: 0.5px solid #55bae2;
}
.shuo_a {
  font-weight: bold;
  line-height: 20px;
  height: 39px;
}
.shuo_b {
  font-size: 10px;
  color: #666;
  line-height: 16px;
}
.shuo_c {
  font-size: 10px;
}
.shuo_c span {
  display: inline-block;
  color: #a50e43;
  padding: 0 1px;
  line-height: 14px;
  background: #f1dadc;
}
.jiage {
  color: #ff0000;
  font-size: 16px;
  line-height: 16px;
  margin-top: 26px;
  overflow: hidden;
}
.jiage_d {
  float: left;
  // color: #999;
  // font-size: 10px;
}
.shen {
  color: #999;
  font-size: 10px;
}
.shouqin {
  color: #ff0000;
  font-size: 10px;
}
.mone {
  // font-weight: bold;
}

.van-progress {
  width: 59%;
  margin-top: 5px;
  float: left;
}
.jindu {
  float: right;
  transform: scale(0.7);
  font-size: 7px;
  color: rgb(255, 255, 255, 0.1);
}
.jintu {
  margin: 0 5px;
  width: 80px;
}
.qianggou {
  // width: 80px;
  // padding: 0 20px;
  height: 38px;
  background: linear-gradient(to left, rgb(255, 96, 52), rgb(238, 10, 36));
  float: right;
  border-radius: 5px;
}
.van-nav-bar {
  height: 50px;
  background: url(../../assets/img/msbeijin.png);

  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

.danwei {
  font-size: 10px;
  vertical-align: text-bottom;
}
.qgg {
  color: #fff;
  font-size: 13px;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
}
.qiangou {
  color: #999;
  font-size: 8px;
  vertical-align: text-bottom;
  margin-left: 3px;
}
.shuo_c span:nth-of-type(n + 2) {
  margin-left: 5px;
}
</style>